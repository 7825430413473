import React from 'react';
import { Link } from "gatsby";

// import MapImg from "../../assets/images/map.png";

const FunFactsArea = ({ title, description, totalDownloads, premiumResources, emailSubscribers }) => {
    return (
        <div className="funfacts-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <h2>{title}</h2>
                    <div className="bar"></div>
                    <p>{description}</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4 col-6">
                        <div className="funfact">
                            <h3>{totalDownloads}</h3>
                            <p>Total Downloads</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-6">
                        <div className="funfact">
                            <h3>{premiumResources}</h3>
                            <p>Premium Resources</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-6">
                        <div className="funfact">
                            <h3>{emailSubscribers}</h3>
                            <p>Email Subscribers</p>
                        </div>
                    </div>
                </div>

                <div className="contact-cta-box">
                    <h3>Have Any Questions About Us?</h3>
                    <p>Don't hesitate to contact us</p>

                    <Link to="/contact/" className="btn btn-primary">
                        Contact Us
                    </Link>
                </div>

                <div className="map-bg">
                <img src="https://ik.imagekit.io/ionicfirebaseapp/gatsby-marketplace/map_-FT5FqF9DtH.png" loading="lazy" alt="map" />
                </div>
            </div>
        </div>
    )
}

export default FunFactsArea;