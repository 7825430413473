import React from 'react';
import { graphql, Link, StaticQuery } from "gatsby";
import * as Icon from 'react-feather';

// Blog Images
import BlogImg9 from "../../assets/images/blog-image/blog9.jpg";
import BlogImg10 from "../../assets/images/blog-image/blog10.jpg";
import BlogImg11 from "../../assets/images/blog-image/blog11.jpg";

// Shape Images
// import Shape1 from "../../assets/images/shape1.png";
// import Shape2 from "../../assets/images/shape2.svg";
// import Shape3 from "../../assets/images/shape3.svg";
// import Shape4 from "../../assets/images/shape4.svg";
import { formatDate, getImageURL } from '../../format-date';
const GET_HOME_PAGE_BLOGS = graphql`
query GetBlogHomePageData {
    allStrapiBlogs(sort: {fields: created_at, order: DESC}) {
        nodes {
          title
          short_description
          blog_image {
            localFile{
                name
                extension
            }
          }
          blog_slug
          author {
            author_name
          }
          created_at
        }
      }
  }
`
const BlogCard = ({ title, description }) => (
    <StaticQuery query={GET_HOME_PAGE_BLOGS} render={blogData => {
        blogData.allStrapiBlogs.nodes.forEach(blog => {
            blog.srcset = "";
            blog.sizes = "";
            blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 300)} 300w,`;
            blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 450)} 450w,`;
            blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 500)} 600w,`;
            blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 600)} 600w,`;
            blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 800)} 800w,`;
            blog.srcset += `${getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension, 1200)} 1200w,`;
            blog.sizes += `(max-width: 1200px) 40vw, 1200px`;
        });
        return (
            <div className="blog-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>{title}</h2>
                        <div className="bar"></div>
                        <p>{description}</p>
                    </div>

                    <div className="row justify-content-center">
                        {blogData.allStrapiBlogs.nodes.map(blog => (
                            <div className="col-lg-4 col-md-6" key={blog.blog_slug}>
                                <div className="single-blog-post-box">
                                    <div className="entry-thumbnail">
                                        <Link to={`/${blog.blog_slug}/`}>
                                            <img
                                                src={getImageURL(blog.blog_image.localFile.name, blog.blog_image.localFile.extension)}
                                                alt={blog.title} srcSet={blog.srcset} sizes={blog.sizes} loading="lazy" style={{ "width": "100%", height: "100%", objectFit: "cover", objectPosition: "center center", opacity: 1, transition: "opacity 500ms ease 0s" }} />
                                        </Link>
                                    </div>

                                    <div className="entry-post-content">
                                        <div className="entry-meta">
                                            <ul>
                                                <li>
                                                    <Link>{blog.author.author_name}</Link>
                                                </li>
                                                <li>{formatDate(blog.created_at, 'MMMM DD YYYY')}</li>
                                            </ul>
                                        </div>

                                        <h3>
                                            <Link to={`/${blog.blog_slug}/`}>
                                                {blog.title}
                                            </Link>
                                        </h3>
                                        <p>{blog.short_description}</p>

                                        <Link to={`/${blog.blog_slug}/`} className="learn-more-btn">
                                            Read Blog <Icon.Plus />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Shape Images */}
                {/* <div className="shape1">
                    <img src={Shape1} alt="shape" />
                </div>
                <div className="shape2 rotateme">
                    <img src={Shape2} alt="shape" />
                </div>
                <div className="shape3">
                    <img src={Shape3} alt="shape" />
                </div>
                <div className="shape4">
                    <img src={Shape4} alt="shape" />
                </div>
                <div className="shape7">
                    <img src={Shape4} alt="shape" />
                </div>
                <div className="shape8 rotateme">
                    <img src={Shape2} alt="shape" />
                </div> */}
            </div>
        );
    }}></StaticQuery>
)

export default BlogCard;